  #body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  min-height: 100%;
  padding: 0;
  margin: 0;
  
}

.tlo {
  background-color: #1f4961;
}

.wrapper {
  padding: 0 0 50px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.head {
  margin-top: -50px;
  height: 50px;
}

  .pad {
    margin-left: 230px !important;
    transition: margin-left .3s ease-in-out;
    -webkit-transition: margin-left .3s ease-in-out;
    background-color: #ecf0f5;
    min-height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pad2 {
    min-height: 100%;
    margin-left: 50px;
    transition: margin-left .3s ease-in-out;
    -webkit-transition: margin-left .3s ease-in-out;
    background-color: #ecf0f5;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .div1 {
  position: relative;
  }

  .div2 {
  position: absolute;
  top: 60%;
  width: 100%;
  }

  .img-responsive {
  object-fit: cover;
  object-position: 100%;
  width: 100%;
  height: 100px;
  }

  .wydzialylink {
    color: #007bff !important;
    text-decoration: none !important;
    background-color: transparent !important;
    cursor:pointer;
  }

  .wydzialylink:hover {
    color: #0056b3 !important;
    text-decoration: underline !important;
  }
    
  .hide {
    display: none;
  }

  .okno {
    min-height: 140 px
  }
  
  .tabela_zadania {
    width: 90%;
  }

  .table {
    font-size: 12px;
  }

  .button-text {
    font-size: 12px;
  }

  ul {
  list-style-type: circle;
  }

  .sumadiv {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .bezramy {
    border: none !important;
  }


  .overf {
    overflow-x:auto;
  }

  .point {
    cursor:pointer;
  }


.main-header {
  position: relative;
  max-height: 50px;
  z-index: 1030;
}

.main-header .logo {
  opacity: 1;
  background-color: #367fa9;
  color: #fff;
  border-bottom: 0 solid transparent;
}


.main-header .logo .logo-lg {
  display: -webkit-flex;
  display: flex;
}


.main-header .logo {
  transition: width 0.3s ease-in-out;
  display: -webkit-flex;
  display: flex;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}

.main-header .logo .logo-lg {
  display: -webkit-flex;
  display: flex;
}

.main-header .navbar-brand {
  color: #fff;
}

.main-header>.navbar {
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  min-height: 50px;
  border-radius: 0;
}

.nav__icon {
  color: #fff;
  font-size: 28px;
  float: left;
  display: block;
  background-color: transparent;
  background-image: none;
}


.schowaj .main-header .logo {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 50px !important;
}
.schowaj .main-header .logo .logo-lg
  {
    display:none;
}
.schowaj .main-header > .navbar{
    margin-left: 50px !important;
}

.schowaj .sidenav {
  -webkit-transform: translate(0, 0);
  transform: translate(0, 0);
  width: 50px !important;
}
.schowaj .sidenav .napis
  {
    display:none;
}

.schowaj .sidenav > .navbar{
  margin-left: 50px !important;
}


.sidenav {
  position: relative;
  max-height: 50px;
  width: 230px;
  z-index: 1030;
  background-color: #1f4961;

}

.sidenav .sidebar {
  opacity: 1;
  height: auto;
  color: #fff;
  border-bottom: 0 solid transparent;
}

.sidenav {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu>li {
  position: relative;
  margin: 0;
  padding: 0;
}

.sidebar-menu>li>a {
  padding: 12px 5px 12px 5px;
  display: block;
  height: 45px;
}

.sidebar-menu>li>a>.fa, .sidebar-menu>li>a>.glyphicon, .sidebar-menu>li>a>.ion {
  width: 20px;
}

.sidebar-menu>li .label, .sidebar-menu>li .badge {
  margin-top: 3px;
  margin-right: 5px;
}

.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}

.sidebar-menu li>a>.fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  margin-top: 3px;
}

.sidebar-menu li.active>a>.fa-angle-left {
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidebar-menu li.active>.treeview-menu {
  display: block;
}

.sidebar-menu .treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 5px;
}

.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 20px;
}

.sidebar-menu .treeview-menu>li {
  margin: 0;
}

.sidebar-menu .treeview-menu>li>a {
  padding: 5px 5px 5px 15px;
  display: block;
  font-size: 14px;
}

.sidebar-menu .treeview-menu>li>a>.fa, .sidebar-menu .treeview-menu>li>a>.glyphicon, .sidebar-menu .treeview-menu>li>a>.ion {
  width: 20px;
}

.sidebar-menu .treeview-menu>li>a>.fa-angle-left, .sidebar-menu .treeview-menu>li>a>.fa-angle-down {
  width: auto;
}

#main-sidebar a i {
  font-size: 28px;
  float: left;
  display: block;
  background-color: transparent;
  background-image: none;
  margin-right: 5px;
  width: 35px;
  text-align: center;
}


.sidebar-menu>li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu>li>a {
border-left: 3px solid transparent;
transition: color .3s;
}

.sidebar-menu>li>a.active {
border-left-color: #3c8dbc;
transition: color .3s;
background-color: #255774;
}

.sidebar a {
color: #b8c7ce;
}

.sidebar-menu li>a {
position: relative;
}

.sidebar-menu>li {
position: relative;
margin: 0;
padding: 0;
}

.sidebar-menu {
list-style: none;
margin: 0;
padding: 0;
}

menu>li:hover>a, .sidebar-menu>li.active>a, .sidebar-menu>li.menu-open>a {
color: #fff;
background: #1e282c;
}

.sidebar-menu>li.active>a {
border-left-color: #3c8dbc;
}

.sidebar-menu>li>.treeview-menu {
margin: 0 1px;
background: #2c3b41;
}

.sidebar-menu>li>.treeview-menu {
margin: 0 1px;
background: #2c3b41;
}

.sidebar-menu .active>.treeview-menu {
display: block;
}

.treeview-menu {
display: none;
list-style: none;
padding: 0;
margin: 0;
padding-left: 5px;
}

.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu>li.header {
white-space: nowrap;
overflow: hidden;
}

.sidebar-menu>li>a:hover, .sidebar-menu .active {
color: #fff;
}

.treeview-menu>li>a:hover, .treeview-menu .active {
color: #fff;
}

#main-sidebar a i {
font-size: 28px;
float: left;
display: block;
background-color: transparent;
background-image: none;
margin-right: 5px;
width: 35px;
text-align: center;
}

#main-sidebar>section>ul>li>ul>li>a>i {
font-size: 12px;
margin-top: 6px;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li {
height: 45px;
}

#main-sidebar>section>ul>li>ul {
transition: height .3s ease-in-out, -webkit-transform .3s ease-in-out;
transition: transform .3s ease-in-out, height .3s ease-in-out;
transition: transform .3s ease-in-out, height .3s ease-in-out, -webkit-transform .3s ease-in-out;
overflow: hidden;
}

.btn-primary {
  background-color: #3c8dbc;
  border-color: #367fa9;
}



.tabela {
  border: 1px solid black;
  font-size: small;
}

.td-1 {
  border-top: 1px solid #000000; 
  border-bottom: 1px solid #000000; 
  border-left: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-2 {
  border-left: 1px solid #000000
}

.td-3 {
  border-right: 1px solid #000000
}

.td-4 {
  border-top: 1px solid #000000; 
  border-bottom: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-5 {
  border-left:1px solid #000000; 
  border-bottom: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-6 {
  border-bottom: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-7 {
  border-bottom: 1px solid #000000
}

.td-8 {
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000
}

.td-9 {
  margin-left:50px;
}
