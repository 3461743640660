  #body {
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: left;
  min-height: 100%;
  padding: 0;
  margin: 0;
  
}

.tlo {
  background-color: #1f4961;
}

.wrapper {
  padding: 0 0 50px 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.head {
  margin-top: -50px;
  height: 50px;
}

  .pad {
    margin-left: 230px !important;
    transition: margin-left .3s ease-in-out;
    -webkit-transition: margin-left .3s ease-in-out;
    background-color: #ecf0f5;
    min-height: 100%;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .pad2 {
    min-height: 100%;
    margin-left: 50px;
    transition: margin-left .3s ease-in-out;
    -webkit-transition: margin-left .3s ease-in-out;
    background-color: #ecf0f5;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .div1 {
  position: relative;
  }

  .div2 {
  position: absolute;
  top: 60%;
  width: 100%;
  }

  .img-responsive {
  object-fit: cover;
  object-position: 100%;
  width: 100%;
  height: 100px;
  }

  .wydzialylink {
    color: #007bff !important;
    text-decoration: none !important;
    background-color: transparent !important;
    cursor:pointer;
  }

  .wydzialylink:hover {
    color: #0056b3 !important;
    text-decoration: underline !important;
  }
    
  .hide {
    display: none;
  }

  .okno {
    min-height: 140 px
  }
  
  .tabela_zadania {
    width: 90%;
  }

  .table {
    font-size: 12px;
  }

  .button-text {
    font-size: 12px;
  }

  ul {
  list-style-type: circle;
  }

  .sumadiv {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .bezramy {
    border: none !important;
  }


  .overf {
    overflow-x:auto;
  }

  .point {
    cursor:pointer;
  }

