

.tabela {
  border: 1px solid black;
  font-size: small;
}

.td-1 {
  border-top: 1px solid #000000; 
  border-bottom: 1px solid #000000; 
  border-left: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-2 {
  border-left: 1px solid #000000
}

.td-3 {
  border-right: 1px solid #000000
}

.td-4 {
  border-top: 1px solid #000000; 
  border-bottom: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-5 {
  border-left:1px solid #000000; 
  border-bottom: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-6 {
  border-bottom: 1px solid #000000; 
  border-right: 1px solid #000000
}

.td-7 {
  border-bottom: 1px solid #000000
}

.td-8 {
  border-bottom: 1px solid #000000;
  border-left: 1px solid #000000
}

.td-9 {
  margin-left:50px;
}