.main-header {
  position: relative;
  max-height: 50px;
  z-index: 1030;
}

.main-header .logo {
  opacity: 1;
  background-color: #367fa9;
  color: #fff;
  border-bottom: 0 solid transparent;
}


.main-header .logo .logo-lg {
  display: flex;
}


.main-header .logo {
  -webkit-transition: width 0.3s ease-in-out;
  -o-transition: width 0.3s ease-in-out;
  transition: width 0.3s ease-in-out;
  display: flex;
  float: left;
  height: 50px;
  font-size: 20px;
  line-height: 50px;
  text-align: center;
  width: 230px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 0 15px;
  font-weight: 300;
  overflow: hidden;
}

.main-header .logo .logo-lg {
  display: flex;
}

.main-header .navbar-brand {
  color: #fff;
}

.main-header>.navbar {
  -webkit-transition: margin-left 0.3s ease-in-out;
  -o-transition: margin-left 0.3s ease-in-out;
  transition: margin-left 0.3s ease-in-out;
  margin-bottom: 0;
  margin-left: 230px;
  border: none;
  min-height: 50px;
  border-radius: 0;
}

.nav__icon {
  color: #fff;
  font-size: 28px;
  float: left;
  display: block;
  background-color: transparent;
  background-image: none;
}


.schowaj .main-header .logo {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
    width: 50px !important;
}
.schowaj .main-header .logo .logo-lg
  {
    display:none;
}
.schowaj .main-header > .navbar{
    margin-left: 50px !important;
}

.schowaj .sidenav {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  width: 50px !important;
}
.schowaj .sidenav .napis
  {
    display:none;
}

.schowaj .sidenav > .navbar{
  margin-left: 50px !important;
}


.sidenav {
  position: relative;
  max-height: 50px;
  width: 230px;
  z-index: 1030;
  background-color: #1f4961;

}

.sidenav .sidebar {
  opacity: 1;
  height: auto;
  color: #fff;
  border-bottom: 0 solid transparent;
}

.sidenav {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  -webkit-transition: -webkit-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -o-transition: -o-transform 0.3s ease-in-out, width 0.3s ease-in-out;
  -webkit-transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.sidebar-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-menu>li {
  position: relative;
  margin: 0;
  padding: 0;
}

.sidebar-menu>li>a {
  padding: 12px 5px 12px 5px;
  display: block;
  height: 45px;
}

.sidebar-menu>li>a>.fa, .sidebar-menu>li>a>.glyphicon, .sidebar-menu>li>a>.ion {
  width: 20px;
}

.sidebar-menu>li .label, .sidebar-menu>li .badge {
  margin-top: 3px;
  margin-right: 5px;
}

.sidebar-menu li.header {
  padding: 10px 25px 10px 15px;
  font-size: 12px;
}

.sidebar-menu li>a>.fa-angle-left {
  width: auto;
  height: auto;
  padding: 0;
  margin-right: 10px;
  margin-top: 3px;
}

.sidebar-menu li.active>a>.fa-angle-left {
  -webkit-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sidebar-menu li.active>.treeview-menu {
  display: block;
}

.sidebar-menu .treeview-menu {
  display: none;
  list-style: none;
  padding: 0;
  margin: 0;
  padding-left: 5px;
}

.sidebar-menu .treeview-menu .treeview-menu {
  padding-left: 20px;
}

.sidebar-menu .treeview-menu>li {
  margin: 0;
}

.sidebar-menu .treeview-menu>li>a {
  padding: 5px 5px 5px 15px;
  display: block;
  font-size: 14px;
}

.sidebar-menu .treeview-menu>li>a>.fa, .sidebar-menu .treeview-menu>li>a>.glyphicon, .sidebar-menu .treeview-menu>li>a>.ion {
  width: 20px;
}

.sidebar-menu .treeview-menu>li>a>.fa-angle-left, .sidebar-menu .treeview-menu>li>a>.fa-angle-down {
  width: auto;
}

#main-sidebar a i {
  font-size: 28px;
  float: left;
  display: block;
  background-color: transparent;
  background-image: none;
  margin-right: 5px;
  width: 35px;
  text-align: center;
}


.sidebar-menu>li {
  position: relative;
  margin: 0;
  padding: 0;
}
.sidebar-menu>li>a {
border-left: 3px solid transparent;
-webkit-transition: color .3s;
-o-transition: color .3s;
transition: color .3s;
}

.sidebar-menu>li>a.active {
border-left-color: #3c8dbc;
-webkit-transition: color .3s;
-o-transition: color .3s;
transition: color .3s;
background-color: #255774;
}

.sidebar a {
color: #b8c7ce;
}

.sidebar-menu li>a {
position: relative;
}

.sidebar-menu>li {
position: relative;
margin: 0;
padding: 0;
}

.sidebar-menu {
list-style: none;
margin: 0;
padding: 0;
}

menu>li:hover>a, .sidebar-menu>li.active>a, .sidebar-menu>li.menu-open>a {
color: #fff;
background: #1e282c;
}

.sidebar-menu>li.active>a {
border-left-color: #3c8dbc;
}

.sidebar-menu>li>.treeview-menu {
margin: 0 1px;
background: #2c3b41;
}

.sidebar-menu>li>.treeview-menu {
margin: 0 1px;
background: #2c3b41;
}

.sidebar-menu .active>.treeview-menu {
display: block;
}

.treeview-menu {
display: none;
list-style: none;
padding: 0;
margin: 0;
padding-left: 5px;
}

.sidebar-menu, .main-sidebar .user-panel, .sidebar-menu>li.header {
white-space: nowrap;
overflow: hidden;
}

.sidebar-menu>li>a:hover, .sidebar-menu .active {
color: #fff;
}

.treeview-menu>li>a:hover, .treeview-menu .active {
color: #fff;
}

#main-sidebar a i {
font-size: 28px;
float: left;
display: block;
background-color: transparent;
background-image: none;
margin-right: 5px;
width: 35px;
text-align: center;
}

#main-sidebar>section>ul>li>ul>li>a>i {
font-size: 12px;
margin-top: 6px;
}

.sidebar-mini.sidebar-collapse .sidebar-menu>li {
height: 45px;
}

#main-sidebar>section>ul>li>ul {
-webkit-transition: -webkit-transform .3s ease-in-out, height .3s ease-in-out;
-o-transition: -o-transform .3s ease-in-out, height .3s ease-in-out;
-webkit-transition: height .3s ease-in-out, -webkit-transform .3s ease-in-out;
transition: height .3s ease-in-out, -webkit-transform .3s ease-in-out;
-o-transition: transform .3s ease-in-out, height .3s ease-in-out;
transition: transform .3s ease-in-out, height .3s ease-in-out;
transition: transform .3s ease-in-out, height .3s ease-in-out, -webkit-transform .3s ease-in-out;
overflow: hidden;
}

.btn-primary {
  background-color: #3c8dbc;
  border-color: #367fa9;
}
